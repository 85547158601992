function Post({post, className}) {
    return (
        <article className={className}>
            <a className={`${className}__cover-link`} href={post.url} aria-hidden="true" tabIndex="-1"></a>
            <div className={`${className}__thumbnail`}>
                {post.img_url && (
                    <img loading="lazy" src={post.img_url} alt={post.title}/>
                )}
            </div>
            {post.type && (
                <span className={`${className}__type`}>{post.type}</span>
            )}
            <h3 className={`${className}__title`}>
                <a href={post.url}>
                    {post.title}
                </a>
            </h3>
            <p className={`${className}__excerpt`}>{post.description}</p>
        </article>
    );
}

export default Post;
