import './App.scss';
import {useEffect, useState} from "react";
import axios from 'axios';
import LoadingSpinner from "./loadingSpinner";
import Post from "./components/Post";

function App() {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [translations, setTranslations] = useState(null);
    const baseURL = `${window.location.origin}/wp-json/valu-read-more-posts/v1`;

    useEffect(() => {
        const loadTranslations = () => {
            const scriptTag = document.getElementById('read-more-posts-app-translations');
            if (scriptTag) {
                const parsedTranslations = JSON.parse(scriptTag.textContent || scriptTag.innerText);
                setTranslations(parsedTranslations);
            } else {
                // Set translations to these hardcoded ones, if not found in the DOM
                setTranslations(
                    {
                        'see_also': 'Katso myös',
                        'fetching_news_failed': 'Uutisten haku epäonnistui',
                    }
                )
            }
        };

        // Load translations when the component mounts
        loadTranslations();

        const fetchPinnedPosts = async () => {
            try {
                const response = await axios.get(`${baseURL}/pinned-lifts/`);
                return response.data;
            } catch (err) {
                setError(err);
                setLoading(false);
                return [];
            }
        };

        const fetchFeaturedPosts = async () => {
            try {
                const response = await axios.get(`${baseURL}/featured-lifts/`);
                const data = response.data;

                // Shuffle the data to randomize the order of posts
                const shuffledData = data.sort(() => 0.5 - Math.random());

                // Get the first 2 posts from the shuffled data
                return shuffledData.slice(0, 2);
            } catch (err) {
                setError(err);
                setLoading(false);
                return [];
            }
        };

        const fetchAllPosts = async () => {
            const pinnedPosts = await fetchPinnedPosts();
            const featuredPosts = await fetchFeaturedPosts();
            setPosts([...pinnedPosts, ...featuredPosts]);
            setLoading(false);
        };

        fetchAllPosts();

    }, []);

    if (loading) {
        return <LoadingSpinner/>;
    }
    /*
    if (error) {
        return translations
            ? <div className="read-more-posts-app__error">Error: {translations.fetching_news_failed}</div>
            : <div className="read-more-posts-app__error">Error: {error.message}</div>;
    }
    */

    if (posts.length === 0) {
        return null;
    }

    return (
        <div className="read-more-posts-app">
            <h2 className="read-more-posts-app__title">{translations.see_also}</h2>
            <ul className="read-more-posts-app__grid">
                {posts.length > 0 && (
                    posts.map((post, index) => (
                        <li key={`${post.ID}-${index}`}>
                            <Post post={post} className="read-more-posts-app__post"/>
                        </li>
                    ))
                )}
            </ul>
        </div>
    );
}

export default App;
